import {useMobile} from "../../MobileContext";
import React, {useEffect, useState} from "react";
import styles from "../../styles/PageLayout.module.css";
import UserHeaderMobile from "../user/mobile/UserHeader";
import DesktopHeader from "../../components/DesktopHeader/DesktopHeader";
import NotificationSection from "../../components/NotificationSection/NotificationSection";
import ProfileModule from "./ProfileModule";

const Profile = () => {
    const isMobile = useMobile();
    const [isNotificationPanelOpen, setIsNotificationPanelOpen] = useState(false);
    const [userRole, setUserRole] = useState("user");

    useEffect(() => {
        const determineRoleFromURL = () => {
            const path = window.location.pathname;
            if (path.includes("/user/")) {
                setUserRole("user");
            } else if (path.includes("/auditor/")) {
                setUserRole("auditor");
            }
        };

        determineRoleFromURL();
    }, []);

    return (
        <div className={styles.mainContainer}>
            {isMobile ? (
                <UserHeaderMobile/>
            ) : (
                <DesktopHeader
                    role={userRole}
                    handleNotificationPanelToggle={() => setIsNotificationPanelOpen(!isNotificationPanelOpen)}
                />
            )}
            <div className={styles.contentContainer}>
                <div className={`${styles.rightSectionContainer} ${isNotificationPanelOpen ? styles.blurred : ""}`}>
                    <ProfileModule isMobile={isMobile} role={userRole}/>
                </div>
                {isNotificationPanelOpen && (
                    <div className={styles.notificationContainer}>
                        <NotificationSection/>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Profile;