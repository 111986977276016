import { useRef, useState } from "react";
import { Box, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

const OtpTextField = styled(TextField)(({ theme }) => ({
    width: '45px',
    height: '55px',
    margin: theme.spacing(0, 0.5),
    '& input': {
        textAlign: 'center',
        fontSize: '1.2rem',
        padding: theme.spacing(1.5, 0),
        direction: 'ltr', // Ensure text fills from left to right
        caretColor: 'transparent', // Hide blinking cursor for better UX
    },
    [theme.breakpoints.down("sm")]: {
        width: '40px',
        height: '50px',
        '& input': {
            fontSize: '1rem',
            padding: theme.spacing(1, 0),
        },
    }
}));

const OTPInput = ({ length = 6, onComplete, onStartEditing }) => {
    const [otp, setOtp] = useState(new Array(length).fill(''));
    const inputRefs = useRef([]);

    const arabicToEnglishDigits = (str) => {
        try {
            return str.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
                .replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
        } catch (error) {
            console.error("Error converting Arabic digits to English:", error);
            return str;
        }
    };

    const handleChange = (index, value) => {
        if (!/^\d*$/.test(value)) return;

        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (onStartEditing) onStartEditing();

        const currentOtp = newOtp.join('');
        onComplete(currentOtp);

        if (value && index < length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace') {
            if (otp[index]) {
                const newOtp = [...otp];
                newOtp[index] = '';
                setOtp(newOtp);
            } else if (index > 0) {
                inputRefs.current[index - 1].focus();
            }
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: { xs: 0.5, sm: 1 },
                marginTop: 2,
                width: '100%',
                flexWrap: 'nowrap',
                direction: 'ltr',
            }}
        >
            {otp.map((digit, index) => (
                <OtpTextField
                    key={index}
                    variant="outlined"
                    value={digit}
                    inputRef={(el) => (inputRefs.current[index] = el)}
                    onChange={(e) => handleChange(index, arabicToEnglishDigits(e.target.value))}
                    onKeyDown={(e) => handleKeyDown(index, e)}
                    inputProps={{
                        maxLength: 1,
                        inputMode: 'numeric',
                        pattern: "[0-9]*",
                        style: { direction: 'ltr' },
                    }}
                />
            ))}
        </Box>
    );
};

export default OTPInput;
