import React, {useState, useMemo} from 'react';
import {Modal, Box, Typography, Table, TableHead, TableBody, TableRow, TableCell, TableSortLabel} from "@mui/material";

const RankingTable = ({headers, data, tableTitle}) => {
    const [showAll, setShowAll] = useState(false);
    const [sortConfig, setSortConfig] = useState({key: null, direction: 'asc'});

    const sortedData = useMemo(() => {
        let sortableItems = [...data];
        if (sortConfig.key !== null) {
            sortableItems.sort((a, b) => {
                let aValue = a[sortConfig.key].value;
                let bValue = b[sortConfig.key].value;

                // Function to convert the formatted string to a number
                const parseFormattedNumber = (str) => {
                    // Remove ریال and commas, then parse as float
                    return parseFloat(str.replace(/[^\d.-]/g, ''));
                };

                // Convert to numbers if they match the format
                if (typeof aValue === 'string' && aValue.includes('ریال')) {
                    aValue = parseFormattedNumber(aValue);
                    bValue = parseFormattedNumber(bValue);
                }
                if (typeof aValue === 'string') {
                    aValue = parseFloat(aValue);
                    bValue = parseFloat(bValue);
                }

                if (aValue < bValue) {
                    return sortConfig.direction === 'asc' ? -1 : 1;
                }
                if (aValue > bValue) {
                    return sortConfig.direction === 'asc' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortableItems;
    }, [data, sortConfig]);

    const requestSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({key, direction});
    };

    const renderTable = (tableData) => (
        <Table>
            <TableHead>
                <TableRow>
                    {headers.map((header, index) => (
                        <TableCell
                            key={index}
                            style={{
                                fontFamily: "'IRANSansFaNum', sans-serif",
                                borderBottom: 'none',
                                fontWeight: 'bold',
                                fontSize: '0.9vw',
                                padding: '0.5vw',
                                textAlign: 'center',
                                whiteSpace: !showAll ? 'nowrap' : 'normal',
                                overflow: !showAll ? 'hidden' : 'visible',
                                textOverflow: !showAll ? 'ellipsis' : 'clip',
                                maxWidth: !showAll ? '10vw' : 'none',
                            }}
                        >
                            {[2, 3].includes(index) ?
                                <TableSortLabel
                                    active={sortConfig.key === index}
                                    direction={sortConfig.key === index ? sortConfig.direction : 'asc'}
                                    onClick={() => requestSort(index)}
                                >
                                    {header.value}
                                </TableSortLabel>
                                :
                                <>
                                    {header.value}
                                </>
                            }

                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {tableData.map((row, rowIndex) => (
                    <TableRow key={rowIndex}>
                        {row.map((cell, cellIndex) => (
                            <TableCell
                                key={cellIndex}
                                style={{
                                    fontFamily: "'IRANSansFaNum', sans-serif",
                                    borderBottom: 'none',
                                    fontSize: '0.9vw',
                                    padding: '0.75vw',
                                    textAlign: 'center',
                                    whiteSpace: !showAll ? 'nowrap' : 'normal',
                                    overflow: !showAll ? 'hidden' : 'visible',
                                    textOverflow: !showAll ? 'ellipsis' : 'clip',
                                    maxWidth: !showAll ? '10vw' : 'none',
                                }}
                            >
                                {cell.value}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );

    const top3ProductRankingData = sortedData.slice(0, 3);

    return (
        <>
            <div style={{height: 'auto', width: '100%'}}>
                {renderTable(top3ProductRankingData)}
                <button onClick={() => setShowAll(true)}
                        style={{
                            background: 'inherit', border: 'none', cursor: 'pointer',
                            fontFamily: 'IRANSans', color: 'var(--primary-light-color)',
                            fontWeight: 'bold', marginTop: '0.5vw', fontSize: '1vw',
                            display: 'flex', alignItems: 'center', gap: '0.5vw', marginRight: 'auto'
                        }}>
                    <span>مشاهده همه</span>
                    <span style={{transform: 'rotate(45deg)', fontSize: '1.5vw'}}>&#x2190;</span>
                </button>
            </div>

            {showAll && (
                <Modal
                    open={showAll}
                    onClose={() => setShowAll(false)}
                    aria-labelledby="modal-title"
                >
                    <Box
                        sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 700,
                            bgcolor: "background.paper",
                            border: "2px solid white",
                            boxShadow: 24,
                            p: 5,
                            borderRadius: '10px',
                            direction: 'rtl',
                            maxHeight: '60vh',
                            overflowY: 'auto'
                        }}
                    >
                        <Typography
                            id="modal-title"
                            variant="h6"
                            component="h2"
                            sx={{
                                textAlign: "center",
                                fontFamily: "'IRANSans', sans-serif",
                                fontWeight: "bold",
                                marginBottom: '2vw',
                                color: 'var(--primary-dark-color)'
                            }}
                        >
                            {tableTitle}
                        </Typography>
                        {renderTable(sortedData)}
                    </Box>
                </Modal>
            )}
        </>
    );
};

export default RankingTable;