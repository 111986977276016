import React, {useState} from 'react';
import {fetchCurrentUser, login} from "../../APIs/auth";
import mobile_styles from "../../styles/auth/LoginMobile.module.css";
import desktop_styles from "../../styles/auth/LoginDesktop.module.css";
import {toast, ToastContainer} from "react-toastify";
import {useNavigate} from "react-router-dom";
import toast_style from "../../styles/Toastify.css";
import {useMobile} from "../../MobileContext";
import auth_image from "../../assets/images/auth_image.svg"
import {user_role_map} from "./Consts";

const Login = () => {
    const navigate = useNavigate();
    const isMobile = useMobile();
    const styles = isMobile ? mobile_styles : desktop_styles;
    const [loading, setLoading] = useState(false);

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        if (name === 'username') {
            setUsername(value);
        } else if (name === 'password') {
            setPassword(value);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const response = await login(username, password);
            localStorage.clear();
            localStorage.setItem('access_token', response.data.access);
            localStorage.setItem('refresh_token', response.data.refresh);

            const user = await fetchCurrentUser();
            const userRole = user_role_map[user.data.user.groups[0]];

            toast.success('با موفقیت وارد شدید.', {autoClose: 3000});

            // FIXME: remove case sensitivity
            setTimeout(() => {
                if (userRole === "Client") {
                    navigate("/user");
                } else if (userRole === "FinancialManager") {
                    navigate("/financial-manager");
                } else if (userRole === "Manager") {
                    navigate("/manager");
                } else if (["Auditor", "CEO", "Admin"]
                    .includes(userRole)) {
                    navigate("/auditor");
                }
                setUsername('');
                setPassword('');
            }, 3000);
        } catch (error) {
            console.error('Login failed:', error);
            toast.error('نام کاربری یا رمز عبور اشتباه است.', {autoClose: 3000});
        } finally {
            setLoading(false);
        }
    };

    const handleForgotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <div className={styles.LoginContainer}>
            <ToastContainer className={toast_style}/>
            {!isMobile && <img className={styles.signupImg} src={auth_image} alt={"img"}/>}
            <div className={styles.LoginContent}>
                <h3 className={styles.HeadingThree}>ایران‌تدارکات</h3>
                <h2 className={styles.HeadingTwo}>ورود</h2>
                <form className={styles.AuthForm} onSubmit={handleSubmit}>
                    <input type="text" id="username" name="username" placeholder={"نام کاربری"}
                           className={styles.InputField} value={username}
                           onChange={handleInputChange}
                           onInvalid={(e) => {
                               e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                           }}
                           onInput={(e) => {
                               e.target.setCustomValidity('');
                           }}
                           required/>
                    <input type="password" id="password" name="password" placeholder={"رمز عبور"}
                           className={styles.InputField} value={password}
                           onChange={handleInputChange}
                           onInvalid={(e) => {
                               e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                           }}
                           onInput={(e) => {
                               e.target.setCustomValidity('');
                           }}
                           required/>
                    <button type="submit" className={styles.ButtonField} disabled={loading}>
                        {!loading ? 'ورود' : 'لطفا صبر کنید ...'}
                    </button>
                </form>
                <span className={styles.TextLinkField} onClick={() => handleForgotPassword()}>
                    رمز عبور خود را فراموش کرده‌اید؟
                </span>
                <span className={styles.TextField}>حساب کاربری ندارید؟</span>
                <button type="submit" className={styles.RegisterButtonField}
                        onClick={() => navigate(`/signup`)}>
                    ثبت‌نام
                </button>
            </div>
        </div>
    );
};

export default Login;
