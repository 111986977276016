import desktop_styles from '../../styles/user/desktop/Profile.module.css';
import mobile_styles from '../../styles/user/mobile/Profile.module.css';
import {MdModeEdit} from "@react-icons/all-files/md/MdModeEdit";
import React, {useEffect, useState} from "react";
import {changePhone, getUserInfo, updateUserInfo} from "../../APIs/user";
import convertDateToShamsi from "../../utils/functions";
import {useNavigate} from "react-router-dom";
import {getRecentUserInquiries} from "../../APIs/inquiry";
import InquiryStatusIcon from "../../components/InquiryStatusIcon";
import ReactLoading from "react-loading";
import InlineError from "../../components/InlineError/InlineError";
import NoInquiryIcon from "../../assets/images/NoInquiryIcon.svg";
import ChangePasswordModal from "./ChangePasswordModal";
import ChangePhoneModal from "./ChangePhoneModal";
import NotificationSettingsModal from "../../components/NotificationSetting/NotificationSetting";

const ProfileModule = ({isMobile, role}) => {
    const styles = isMobile ? mobile_styles : desktop_styles;
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [recentInquiries, setRecentInquiries] = useState([]);
    const [loadingUserInfo, setLoadingUserInfo] = useState(true);
    const [loadingInquiries, setLoadingInquiries] = useState(true);
    const [loadingSaveChanges, setLoadingSaveChanges] = useState(false);
    const [editMode, setEditMode] = useState({field: null, value: ""});
    const [showChangePassModal, setShowChangePassModal] = useState(false);
    const [showChangePhoneModal, setShowChangePhoneModal] = useState(false);
    const [showNotificationSettingsModal, setShowNotificationSettingsModal] = useState(false);

    // Validation errors
    const [showEmailStructureError, setShowEmailStructureError] = useState(false);
    const [showUsernameStructureError, setShowUsernameStructureError] = useState(false);
    const [showUsernameAlreadyExistsError, setShowUsernameAlreadyExistsError] = useState(false);
    const [showFullnameEmptyError, setShowFullnameEmptyError] = useState(false);

    // Regex patterns
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const formatNumber = (num) => {
        return new Intl.NumberFormat('en-US').format(num);
    };

    useEffect(() => {
        const fetchUserInfo = async () => {
            try {
                setLoadingUserInfo(true);
                const userData = await getUserInfo();
                setUserInfo(userData.data.user);
            } catch (error) {
                console.error('Error fetching user info:', error);
                setUserInfo({});
            } finally {
                setLoadingUserInfo(false);
            }
        }

        const fetchRecentInquiries = async () => {
            try {
                setLoadingInquiries(true);
                const recentInquiriesData = await getRecentUserInquiries();
                setRecentInquiries(recentInquiriesData.data.inquiries);
            } catch (error) {
                console.error('Error fetching recent inquiries:', error);
                setRecentInquiries([]);

            } finally {
                setLoadingInquiries(false);
            }
        }

        fetchUserInfo();
        if (role === "user") {
            fetchRecentInquiries();
        }
    }, []);

    const handleEditClick = (field) => {
        // Reset all validation errors when starting edit
        setShowEmailStructureError(false);
        setShowUsernameStructureError(false);
        setShowUsernameAlreadyExistsError(false);
        setShowFullnameEmptyError(false);

        setEditMode({field, value: userInfo[field]});
    };

    const handleInputChange = (e) => {
        setEditMode((prev) => ({...prev, value: e.target.value}));
    };

    const handleSave = async () => {
        try {
            setLoadingSaveChanges(true);

            // Reset validation errors
            setShowEmailStructureError(false);
            setShowUsernameStructureError(false);
            setShowUsernameAlreadyExistsError(false);

            // Validate based on field type
            if (editMode.field === "fullname") {
                // Check if fullname is empty
                if (!editMode.value.trim()) {
                    // You'll need to add a state for this error
                    setShowFullnameEmptyError(true);
                    return;
                }
            } else if (editMode.field === "username") {
                // Check username format
                if (!/^[a-zA-Z0-9_]+$/.test(editMode.value)) {
                    setShowUsernameStructureError(true);
                    return;
                }

            } else if (editMode.field === "email") {
                // Check email format
                if (!emailRegex.test(editMode.value)) {
                    setShowEmailStructureError(true);
                    return;
                }
            }

            // If we made it here, validation passed, so update the user info
            await updateUserInfo(editMode.field, editMode.value);
            setUserInfo((prev) => ({...prev, [editMode.field]: editMode.value}));
            setEditMode({field: null, value: ""});
        } catch (error) {
            console.error("Error updating user info:", error);

            // Handle API errors
            if (error.response && error.response.data) {
                if (error.response.status === 400) {
                    if (error.response.data.error === "Username already exists. Please try another one.") {
                        setShowUsernameAlreadyExistsError(true);
                    } else {
                        // Handle other errors
                        navigate("/500");
                    }
                } else {
                    navigate("/500");
                }
            } else {
                navigate("/500");
            }
        } finally {
            setLoadingSaveChanges(false);
        }
    };

    const handleChangePhone = async (new_phone, verification_token) => {
        try {
            await changePhone(userInfo.phone, new_phone, verification_token);

            window.location.reload();
        } catch (error) {
            console.error("Error updating user phone:", error);
        }
    };

    return (
        <div className={role === "user" ? styles.container  : styles.auditor_container}>
            <h1 className={styles.pageTitle}>پروفایل</h1>

            <div className={styles.gridContainer}>
                {/* Personal Info Card */}
                <div className={styles.columnContainer}>
                    <div className={styles.card}>
                        {!isMobile && <h2 className={styles.sectionTitle}>مشخصات فردی</h2>}
                        <span className={styles.sectionHint}>
                            برای ویرایش نام و نام خانوادگی، نام کاربری، شماره تلفن و یا آدرس ایمیل، بر روی
                            علامت {<MdModeEdit className={styles.editButton} style={{cursor: 'default'}}/>} کلیک کنید.
                        </span>
                        {loadingUserInfo ? (
                            <div className={styles.loading_container}>
                                <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                              height='50px'/>
                            </div>
                        ) : (
                            <div className={styles.infoGrid}>
                                {["fullname", "phone", "username", "email"].map((field) => (
                                    <div key={field} className={styles.infoItem}>
                                        <div className={styles.label_container}>
                                            <label
                                                style={{fontWeight: 'bold'}}>{field === "fullname" ? "نام و نام خانوادگی" : field === "phone" ? "شماره تلفن" : field === "username" ? "نام کاربری" : "آدرس ایمیل"}</label>
                                            {field === "phone" ?
                                                <MdModeEdit className={styles.editButton} title="ویرایش"
                                                            onClick={() => setShowChangePhoneModal(true)}/>
                                                :
                                                <MdModeEdit className={styles.editButton} title="ویرایش"
                                                            onClick={() => handleEditClick(field)}/>
                                            }
                                        </div>
                                        {editMode.field === field ? (
                                            <div className={styles.editInputContainer}>
                                                <div className={styles.editInputField}>
                                                    <input
                                                        type="text"
                                                        className={styles.editInput}
                                                        value={editMode.value}
                                                        onChange={handleInputChange}
                                                    />
                                                    <button className={styles.saveButton} onClick={handleSave}
                                                            disabled={loadingSaveChanges}>ذخیره
                                                    </button>
                                                </div>

                                                {/* Show validation errors */}
                                                {field === "email" && showEmailStructureError && (
                                                    <InlineError
                                                        errorMessage={"آدرس ایمیل به درستی وارد نشده است."}
                                                        device={isMobile ? "mobile" : "desktop"}
                                                    />
                                                )}
                                                {field === "username" && showUsernameStructureError && (
                                                    <InlineError
                                                        errorMessage={"نام کاربری باید فقط شامل حروف انگلیسی و علامت خط تیره (_) باشد."}
                                                        device={isMobile ? "mobile" : "desktop"}
                                                    />
                                                )}
                                                {field === "username" && showUsernameAlreadyExistsError && (
                                                    <InlineError
                                                        errorMessage={"با این نام کاربری قبلا یک حساب ثبت شده است."}
                                                        device={isMobile ? "mobile" : "desktop"}
                                                    />
                                                )}
                                                {field === "fullname" && showFullnameEmptyError && (
                                                    <InlineError
                                                        errorMessage={"نام و نام خانوادگی نمی‌تواند خالی باشد."}
                                                        device={isMobile ? "mobile" : "desktop"}
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            <div className={styles.displayValue}>{userInfo[field]}</div>
                                        )}
                                    </div>
                                ))}
                                <div className={styles.infoItem}>
                                    <label style={{fontWeight: 'bold'}}>نام سازمان</label>
                                    <div className={styles.displayValue}>{userInfo.organization}</div>
                                </div>
                                <div className={styles.infoItem}>
                                    <label style={{fontWeight: 'bold'}}>تاریخ عضویت</label>
                                    <div
                                        className={styles.displayValue}>{convertDateToShamsi(userInfo.date_joined)}</div>
                                </div>
                            </div>
                        )}
                        <div className={styles.buttonGroup}>
                            <button className={styles.viewAllButton} onClick={() => setShowChangePassModal(true)}>
                                ویرایش رمز عبور
                            </button>

                            <button className={styles.newRequestButton}
                                    onClick={() => setShowNotificationSettingsModal(true)}>
                                تنظیمات اعلان‌ها
                            </button>
                        </div>
                        {showChangePassModal &&
                            <ChangePasswordModal
                                open={showChangePassModal}
                                onClose={() => setShowChangePassModal(false)}
                            />
                        }
                        {showChangePhoneModal &&
                            <ChangePhoneModal
                                open={showChangePhoneModal}
                                onClose={() => setShowChangePhoneModal(false)}
                                onSave={handleChangePhone}
                            />
                        }
                        {showNotificationSettingsModal &&
                            <NotificationSettingsModal
                                open={showNotificationSettingsModal}
                                onClose={() => setShowNotificationSettingsModal(false)}
                            />
                        }
                    </div>
                </div>

                {!isMobile && role === "user" &&
                    <div className={styles.secondColumnContainer}>
                        <div className={styles.card}>
                            <h2 className={styles.sectionTitle}>عملکرد کلی</h2>
                            {loadingUserInfo ? (
                                <div className={styles.loading_container}>
                                    <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                                  height='50px'/>
                                </div>
                            ) : (
                                <>
                                    <div className={styles.statsContainer}>
                                        <div className={styles.statItem}>
                                            <label>تعداد کل درخواست‌های تایید ‌شده</label>
                                            <div className={styles.value}>{userInfo.inquiry_count}</div>
                                        </div>

                                        <div className={styles.statItem}>
                                            <label>مجموع قیمت درخواست‌های تایید ‌شده</label>
                                            <div className={styles.value}>{formatNumber(userInfo.total_spending)} ریال
                                            </div>
                                        </div>
                                    </div>
                                    <button className={styles.viewReportsButton}
                                            onClick={() => navigate('/user/dashboard')}>مشاهده
                                        گزارش‌ها
                                    </button>
                                </>
                            )}
                        </div>

                        <div className={styles.card}>
                            <h2 className={styles.sectionTitle}>درخواست‌های اخیر</h2>
                            {loadingInquiries ? (
                                <div className={styles.loading_container}>
                                    <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='75px'
                                                  height='50px'/>
                                </div>
                            ) : (
                                <>
                                    {recentInquiries.length === 0 ?
                                        <div className={styles.EmptyInquiriesContainer}>
                                            <img src={NoInquiryIcon} className={styles.EmptyInquiries}
                                                 alt={"No Inquiries!"}/>
                                            <span className={styles.EmptyInquiriesMessage}>
                                                شما هنوز درخواستی ثبت نکرده‌اید.
                                            </span>
                                        </div>
                                        :
                                        <div className={styles.requestsContainer}>
                                            {recentInquiries.map(request => (
                                                <div key={request.id} className={styles.requestItemContainer}
                                                     onClick={() => navigate(`/user/inquiry/${request.id}`)}>
                                                    <div className={styles.requestItem}>

                                                        <div className={styles.secondRowContainer}>
                                                            <span
                                                                className={styles.requestSubject}>{request.subject}</span>
                                                            <InquiryStatusIcon inquiryStatus={request.status}/>
                                                        </div>

                                                        <span className={styles.requestDate}>
                                                            تاریخ ثبت: {convertDateToShamsi(request.submitted_date)}
                                                        </span>

                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                    <div className={styles.buttonGroup}>
                                        <button className={styles.viewAllButton}
                                                onClick={() => navigate("/user")}>مشاهده
                                            درخواست‌ها
                                        </button>
                                        <button className={styles.newRequestButton}
                                                onClick={() => navigate("/user/new-inquiry")}>ثبت درخواست جدید
                                        </button>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
};

export default ProfileModule;