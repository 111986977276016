import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {toast, ToastContainer} from "react-toastify";
import toast_style from "../../styles/Toastify.css";
import auth_image from "../../assets/images/auth_image.svg";
import {useMobile} from "../../MobileContext";
import {resetPassword} from "../../APIs/auth";
import InlineError from "../../components/InlineError/InlineError";
import OTPVerification from "./OTPVerification";

import mobile_styles from "../../styles/auth/SignupMobile.module.css";
import desktop_styles from "../../styles/auth/SignupDesktop.module.css";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const isMobile = useMobile();
    const styles = isMobile ? mobile_styles : desktop_styles;
    const [loading, setLoading] = useState(false);

    const [step, setStep] = useState(1);
    const [phone, setPhone] = useState('');
    const [OTPVerificationToken, setOTPVerificationToken] = useState('');

    // Password fields
    const [password, setPassword] = useState('');
    const [rePassword, setRePassword] = useState('');

    // Errors
    const [showRePasswordError, setShowRePasswordError] = useState(false);
    const [showPasswordStructureError, setShowPasswordStructureError] = useState(false);
    const [showPasswordLengthError, setShowPasswordLengthError] = useState(false);

    const handleInputChange = (event) => {
        const {name, value} = event.target;
        if (name === 'password') {
            setPassword(value);
        } else if (name === 're-password') {
            setRePassword(value);
        }
    };

    // Handle OTP verification completion
    const handleVerificationComplete = (verifiedPhone, verificationToken) => {
        setPhone(verifiedPhone);
        setOTPVerificationToken(verificationToken);
        setStep(3); // Move to password reset form
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            setShowRePasswordError(false);
            setShowPasswordStructureError(false);
            setShowPasswordLengthError(false);

            if (password.length < 8) {
                setShowPasswordLengthError(true);
                setLoading(false);
                return;
            }

            if (!/^[a-zA-Z0-9_@#%&]+$/.test(password)) {
                setShowPasswordStructureError(true);
                setLoading(false);
                return;
            }

            if (password !== rePassword) {
                setShowRePasswordError(true);
                setLoading(false);
                return;
            }

            await resetPassword(phone, password, OTPVerificationToken);

            toast.success('رمز عبور با موفقیت ویرایش شد.', {autoClose: 3000});

            setTimeout(() => {
                navigate("/login");
            }, 3000);

        } catch (error) {
            console.error('Password reset failed:', error);
            toast.error('خطایی در ویرایش رمز عبور رخ داده است.', {autoClose: 3000});
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className={styles.SignupContainer}>
            <ToastContainer className={toast_style}/>
            {!isMobile &&
                <img
                    className={styles.signupImg}
                    src={auth_image}
                    alt="img"
                />
            }
            {(step === 1 || step === 2) && (
                <OTPVerification
                    otp_type={"password_reset"}
                    onVerificationComplete={handleVerificationComplete}
                    styles={styles}
                    isMobile={isMobile}
                />
            )}
            {step === 3 &&
                <div className={styles.SignupContent}>
                    <h3 className={styles.HeadingThree}>ایران‌تدارکات</h3>
                    <h2 className={styles.HeadingTwo}>بازیابی رمز عبور</h2>
                    <form className={styles.ForgotForm} onSubmit={handleSubmit}>
                        {!isMobile && <h3 className={styles.OtpInfo}>رمز عبور جدید</h3>}
                        <input type="password" id="password" name="password" placeholder={"رمز عبور"}
                               className={styles.OTPInputField} value={password}
                               onChange={handleInputChange}
                               onInvalid={(e) => {
                                   e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                               }}
                               onInput={(e) => {
                                   e.target.setCustomValidity('');
                               }}
                               required/>
                        {showPasswordStructureError && (
                            <div style={{alignSelf: 'center'}}>
                                <InlineError
                                    errorMessage={"در رمز عبور، تنها می‌توانید از کاراکترهای ویژه _ @ # % & استفاده کنید."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            </div>
                        )}
                        {showPasswordLengthError && (
                            <div style={{alignSelf: 'center'}}>
                                <InlineError
                                    errorMessage={"طول رمز عبور باید بیشتر از ۸ کاراکتر باشد."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            </div>
                        )}
                        <input type="password" id="re-password" name="re-password"
                               placeholder={"تکرار رمز عبور"}
                               className={styles.OTPInputField} value={rePassword}
                               onChange={handleInputChange}
                               onInvalid={(e) => {
                                   e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                               }}
                               onInput={(e) => {
                                   e.target.setCustomValidity('');
                               }}
                               required/>
                        {showRePasswordError && (
                            <div style={{alignSelf: 'center'}}>
                                <InlineError
                                    errorMessage={"تکرار رمز عبور به درستی وارد نشده است."}
                                    device={isMobile ? "mobile" : "desktop"}
                                />
                            </div>
                        )}
                        <button type="submit" className={styles.OPTButton} disabled={loading}>
                            {loading ? "لطفا صبر کنید ..." : "ویرایش رمز عبور"}
                        </button>
                    </form>
                    <span className={styles.TextField}>رمز عبور خود را به خاطر آوردید؟</span>
                    <button
                        type="button"
                        className={styles.LoginButtonField}
                        onClick={() => navigate("/login")}
                    >
                        بازگشت به صفحه ورود
                    </button>
                </div>
            }
        </div>
    );
};

export default ForgotPassword;