import React, { useState, useEffect } from "react";
import styles from "./OTPTimer.module.css";

const OTPTimer = ({ duration = 120, onResend }) => {
    const [timeLeft, setTimeLeft] = useState(duration);
    const [isDisabled, setIsDisabled] = useState(true);

    useEffect(() => {
        if (timeLeft > 0) {
            const timer = setInterval(() => setTimeLeft((prev) => prev - 1), 1000);
            return () => clearInterval(timer);
        } else {
            setIsDisabled(false);
        }
    }, [timeLeft]);

    const handleResend = () => {
        setTimeLeft(duration);
        setIsDisabled(true);
        onResend();
    };

    // Format time as MM:SS
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    const formattedTime = `${minutes}:${seconds.toString().padStart(2, "0")}`;

    return (
        <div className={styles.timerContainer}>
            {isDisabled ? (
                <p className={styles.timerText}>
                    اگر کدی دریافت نکردید، <strong>
                        {minutes > 0 ? `${minutes} دقیقه و ` : ""}
                        {seconds} ثانیه</strong> صبر کنید، سپس می‌توانید درخواست کد جدید بدهید.
                </p>
            ) : (
                <p className={styles.resendText} onClick={handleResend}>
                    کد را دریافت نکردید؟ <span className={styles.resendLink}>ارسال مجدد کد</span>
                </p>
            )}
        </div>
    );
};

export default OTPTimer;
