import axios from "./base";

export function getAllPaginatedUsers(page, pageSize, sort_key, sort_direction, search_query) {
    const params = new URLSearchParams();

    if (sort_key) {
        params.append('sort_key', sort_key);
    }
    if (sort_direction) {
        params.append('sort_direction', sort_direction);
    }
    if (search_query) {
        params.append('search_query', search_query);
    }
    params.append('page', page);
    params.append('page_size', pageSize);

    return axios.get(`user/all/paginated/?${params.toString()}`);
}

export function getUserInfo() {
    return axios.get(`user/info/`);
}

export function updateUserInfo(field, value) {
    return axios.post(`user/update-info/`, {field: field, value: value});
}

export function changePassword(old_password, new_password) {
    return axios.post(`user/change-password/`, {old_password: old_password, new_password: new_password});
}

export function changePhone(old_phone, new_phone, otp_verification_token) {
    return axios.post(`user/change-phone/`, {
        old_phone: old_phone,
        new_phone: new_phone,
        otp_verification_token: otp_verification_token
    });
}

export function updateUserNotificationSettings(settings) {
    return axios.post(`user/modify-sms-notif-preferences/`, settings);
}

export function getUserSMSNotificationPreferences() {
    return axios.get(`user/sms-notif-preferences/`);
}