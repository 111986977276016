import React, {useEffect, useState} from "react";
import styles from "../../styles/common/mobile/InquiryHistory.module.css";
import {toast, ToastContainer} from "react-toastify";
import toast_style from "../../styles/Toastify.css";
import {MdClose} from "@react-icons/all-files/md/MdClose";
import {useParams} from "react-router-dom";
import TimeLine from "../../components/TimeLine/TimeLine";
import AutoGrowInput from "../../components/AutoGrowInput";

import {addComment, getInquiryHistory} from "../../APIs/inquiry";
import ReactLoading from "react-loading";

const InquiryHistory = ({onClose}) => {
    const [showModal, setShowModal] = useState(false);
    const {id} = useParams();
    const [isFetchingComments, setIsFetchingComments] = useState(false);
    const [isSendingComment, setIsSendingComment] = useState(false);

    const [timelineItems, setTimelineItems] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [refetch, setRefetch] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowModal(true);
        }, 50); // Adjust the delay as needed

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const fetchComments = async () => {
            setIsFetchingComments(true);
            try {
                const {data: {history}} = await getInquiryHistory(id);
                const transformedItems = history.reduce((acc, item, index, array) => {
                    const prevItem = index > 0 ? array[index - 1] : null;

                    const newItem = {
                        date: new Date(item.date).toLocaleDateString('fa-IR'),
                        time: new Date(item.date).toLocaleTimeString('fa-IR', {hour: '2-digit', minute: '2-digit'}),
                        content: item.action ?
                            item.action === 1 ? "ایجاد درخواست" :
                                item.action === 3 ? "ویرایش درخواست" :
                                    item.action === 4 ? "تغییر وضعیت درخواست به در حال بررسی" :
                                        item.action === 5 ? "تغییر وضعیت درخواست به بررسی شده" :
                                            item.action === 6 ? "تغییر وضعیت درخواست به خاتمه یافته" :
                                                item.action === 7 ? "تغییر وضعیت درخواست به لغو شده" :
                                                    item.action === 8 ? "ارجاع درخواست به مسئول خرید" :
                                                        "" : item.text,
                        author: item.fullname ? item.fullname : '',
                        isGreen: !!item.action,
                    };

                    acc.push(newItem);

                    return acc;
                }, []);

                setTimelineItems(transformedItems);

            } catch (error) {
                console.error("Error fetching comments : ", error);
            } finally {
                setIsFetchingComments(false);
            }
        };

        fetchComments();
    }, [refetch]);

    const handleClose = () => {
        onClose();
    };

    const handleCommentChange = (value) => {
        setNewComment(value);
    }

    const handleAddNewComment = async () => {
        if (!newComment.trim()) {
            toast.warning("لطفا یک کامنت وارد کنید.", {autoClose: 3000});
            return;
        }

        setIsSendingComment(true);
        try {
            await addComment(id, newComment.trim(), true);
            // toast.success("کامنت جدید با موفقیت اضافه شد.", {autoClose: 3000});
        } catch (error) {
            console.error("Error adding comment:", error);
            toast.error("خطا در افزودن کامنت جدید.", {autoClose: 3000});
        } finally {
            setIsSendingComment(false);
            setNewComment('');
            setRefetch(prev => !prev);
        }
    };

    return (
        <div className={`${styles.modalOverlay} ${showModal ? styles.show : ''}`}>
            <div id="modal" className={styles.modal}>
                <ToastContainer className={toast_style}/>
                <div className={styles.InquiryHistoryContainer}>
                    <div className={styles.HeaindgContainer}>
                        <h1 className={styles.heading}>گردش کار</h1>
                        <MdClose className={styles.closeIcon} onClick={handleClose}/>
                    </div>
                    <div className={styles.FirstHistoryContainer}>
                        <div className={styles.HistoryContainer}>

                            {isFetchingComments ?
                                <div className={styles.loading_container}>
                                    <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)"
                                                  width='50px'
                                                  height='50px'/>
                                </div>
                                :
                                <TimeLine items={timelineItems} isMobile={true}/>
                            }
                        </div>
                    </div>
                </div>
                <div className={styles.AddCommentContainer}>
                    <h1 className={styles.heading}>اضافه کردن کامنت</h1>
                    <AutoGrowInput
                        value={newComment}
                        onInputChange={handleCommentChange}
                        styleClassName={styles.inputText}
                    />
                    <button
                        className={styles.SendButton}
                        onClick={handleAddNewComment}
                        disabled={isSendingComment}
                    >
                        {isSendingComment ? 'لطفا صبر کنید ...' : 'ارسال'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default InquiryHistory;