import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    FormHelperText,
    CircularProgress,
    Box,
    Typography, Snackbar,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {sendVerificationCode, verifyCode} from '../../APIs/auth';
import {Alert} from "@mui/lab";

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-paper': {
        width: '500px',
        maxWidth: '90vw',
        padding: theme.spacing(2),
    },
    '& *': {
        fontFamily: 'IRANSans !important',
    },
    '& .MuiDialogTitle-root': {
        padding: theme.spacing(1, 2),
        textAlign: 'center',
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1, 2),
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        '& .MuiButton-root': {
            width: '20%',
        }
    },
    '& .MuiTextField-root': {
        marginBottom: theme.spacing(2),
    },
}));

// For the OTP input fields
const OtpTextField = styled(TextField)(({theme}) => ({
    width: '50px',
    margin: theme.spacing(0, 0.5),
    '& input': {
        textAlign: 'center',
        fontSize: '1.2rem',
        padding: theme.spacing(1.5, 0),
    },
}));

const ChangePhoneModal = ({open, onClose, currentPhone, onSave}) => {
    // State for the steps of the process
    const [step, setStep] = useState(1); // 1: Enter new phone, 2: Enter OTP

    // Phone number state
    const [phone, setPhone] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const PHONE_ALREADY_EXISTS = "Phone already exists. Please try another one.";
    const SMS_SEND_FAILURE_IP_ADDRESS = "Failed to send SMS. Possible invalid IP address.";
    const INVALID_OR_EXPIRED_OTP = "OTP expired or invalid.";

    // OTP state
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [otpError, setOtpError] = useState('');
    const [otpSent, setOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);

    // Loading and error states
    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState('');
    const [success, setSuccess] = useState(false);

    // Refs for OTP input elements
    const otpRefs = Array(6).fill(0).map(() => React.createRef());

    // Validate phone number - simple regex for Iran format
    const validatePhone = (phoneNumber) => {
        // Persian mobile pattern: 09xxxxxxxxx (11 digits starting with 09)
        const phonePattern = /^09\d{9}$/;
        return phonePattern.test(phoneNumber);
    };

    const arabicToEnglishDigits = (str) => {
        try {
            str = String(str); // Convert to string if it's not already
            return str.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
                .replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
        } catch (error) {
            console.error("Error converting Arabic digits to English:", error);
            return str; // Return the original input if an error occurs
        }
    };

    // Handle phone number change
    const handlePhoneChange = (event) => {
        const value = arabicToEnglishDigits(event.target.value);
        setPhone(value);
        setPhoneError('');
        setApiError('');
    };

    // Handle OTP input change
    const handleOtpChange = (index, event) => {
        const value = event.target.value;

        // Only allow digits
        if (value && !/^\d+$/.test(value)) {
            return;
        }

        // Update the OTP array
        const newOtp = [...otp];
        newOtp[index] = value.slice(0, 1); // Only take the first character
        setOtp(newOtp);
        setOtpError('');
        setApiError('');

        // Auto-focus to next input if a digit was entered
        if (value && index < 5) {
            otpRefs[index + 1].current.focus();
        }
    };

    // Handle key down in OTP fields (for backspace)
    const handleOtpKeyDown = (index, event) => {
        // Move focus to previous input on backspace if current input is empty
        if (event.key === 'Backspace' && !otp[index] && index > 0) {
            otpRefs[index - 1].current.focus();
        }
    };

    // Handle sending OTP
    const handleSendOtp = async () => {
            // Validate phone number
            if (!validatePhone(phone)) {
                setPhoneError('شماره تلفن وارد شده معتبر نیست. فرمت صحیح: 09xxxxxxxxx');
                return;
            }

            // Check if phone is different from current phone
            if (phone === currentPhone) {
                setPhoneError('شماره تلفن جدید نمی‌تواند با شماره تلفن فعلی یکسان باشد.');
                return;
            }

            setIsLoading(true);
            setApiError('');

            try {
                const response = await sendVerificationCode(phone, 'change_phone');
                setOtpSent(true);
                setStep(2);
                setCountdown(120); // 2 minutes countdown
            } catch (error) {
                if (error.response) {
                    if (error.response.data) {
                        if (error.response.status === 400) {
                            if (error.response.data.error === PHONE_ALREADY_EXISTS) {
                                setApiError('با این شماره تلفن قبلا یک حساب ثبت شده است.');
                            } else if (error.response.data.error === SMS_SEND_FAILURE_IP_ADDRESS) {
                                setApiError('خطایی در ارسال کد یک‌بار مصرف رخ داده است. لطفاً از خاموش بودن فیلترشکن خود اطمینان حاصل کنید.');
                            } else if (error.response.data.error === "OTP service is not available between 10 PM and 8 AM.") {
                                setApiError('سرویس پیامک از ساعت ۱۰ شب تا ۸ صبح غیرفعال می‌باشد.');
                            }
                        }
                    } else {
                        setApiError('خطایی رخ داده است. لطفا مجددا تلاش کنید.');
                    }
                } else {
                    setApiError('خطا در اتصال به سرور. لطفا مجددا تلاش کنید.');
                }
            } finally {
                setIsLoading(false);
            }
        }
    ;

    // Handle OTP verification
    const handleVerifyOtp = async () => {
        // Check if all OTP digits are filled
        const otpValue = otp.join('');
        if (otpValue.length !== 6) {
            setOtpError('لطفا کد 6 رقمی را به طور کامل وارد کنید.');
            return;
        }

        setIsLoading(true);
        setApiError('');

        try {
            const response = await verifyCode(phone, otpValue);
            const verification_token = response.data.verification_token
            setSuccess(true);

            // Call the onSave callback with the new phone number
            if (onSave) {
                await onSave(phone, verification_token);
            }

            // Close the modal after success
            setTimeout(() => {
                resetForm();
                onClose();
            }, 3000);
        } catch (error) {
            if (error.response) {
                if (error.response.data.error === INVALID_OR_EXPIRED_OTP) {
                    setOtpError('کد وارد شده صحیح نیست یا منقضی شده است. لطفا مجددا تلاش کنید.');
                } else {
                    setApiError('خطایی رخ داده است. لطفا مجددا تلاش کنید.');
                }
            } else {
                setApiError('خطا در اتصال به سرور. لطفا مجددا تلاش کنید.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Handle resend OTP
    const handleResendOtp = async () => {
        if (countdown > 0) return;

        setIsLoading(true);
        setOtp(['', '', '', '', '', '']);
        setApiError('');
        setOtpError('');

        try {
            await sendVerificationCode(phone, 'change_phone');
            setOtpSent(true);
            setCountdown(120); // Reset countdown
        } catch (error) {
            if (error.response) {
                setApiError('خطایی رخ داده است. لطفا مجددا تلاش کنید.');
            } else {
                setApiError('خطا در اتصال به سرور. لطفا مجددا تلاش کنید.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    // Countdown timer effect
    useEffect(() => {
        let timer;
        if (countdown > 0) {
            timer = setTimeout(() => setCountdown(countdown - 1), 1000);
        }
        return () => clearTimeout(timer);
    }, [countdown]);

    // Format countdown time
    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
    };

    // Reset form state
    const resetForm = () => {
        setStep(1);
        setPhone('');
        setPhoneError('');
        setOtp(['', '', '', '', '', '']);
        setOtpError('');
        setOtpSent(false);
        setCountdown(0);
        setApiError('');
        setSuccess(false);
    };

    // Handle modal close
    const handleClose = () => {
        resetForm();
        onClose();
    };

    return (
        <StyledDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="change-phone-dialog-title"
            dir="rtl"
        >
            <DialogTitle id="change-phone-dialog-title" color="grey" fontWeight="bold">
                ویرایش شماره تلفن
            </DialogTitle>

            <DialogContent>
                {step === 1 ? (
                    // Step 1: Enter new phone number
                    <>
                        <TextField
                            margin="dense"
                            name="phone"
                            label="شماره تلفن جدید"
                            type="tel"
                            fullWidth
                            variant="outlined"
                            value={phone}
                            onChange={handlePhoneChange}
                            error={!!phoneError}
                            helperText={phoneError}
                            placeholder="09xxxxxxxxx"
                            inputProps={{
                                maxLength: 11,
                                style: {textAlign: 'right', direction: 'rtl'}
                            }}
                            InputLabelProps={{
                                style: {left: 'auto', right: '1.75rem'}
                            }}
                            sx={{
                                '& .MuiOutlinedInput-root': {
                                    '& > fieldset': {
                                        textAlign: 'right',
                                    },
                                },
                                '& .MuiInputLabel-root': {
                                    transformOrigin: 'right !important'
                                },
                            }}
                        />
                    </>
                ) : (
                    // Step 2: Enter OTP
                    <>
                        <Typography variant="body1" sx={{marginBottom: 2, textAlign: 'center'}}>
                            کد تأیید به شماره {phone} ارسال شد.
                        </Typography>

                        <Box sx={{display: 'flex', justifyContent: 'center', marginY: 3, flexDirection: 'row-reverse'}}>
                            {otp.map((digit, index) => (
                                <OtpTextField
                                    key={index}
                                    inputRef={otpRefs[index]} // Use normal index order (left to right)
                                    variant="outlined"
                                    value={digit}
                                    onChange={(e) => handleOtpChange(index, e)} // Use normal index
                                    onKeyDown={(e) => handleOtpKeyDown(index, e)} // Use normal index
                                    inputProps={{
                                        maxLength: 1,
                                        inputMode: 'numeric',
                                        style: {direction: 'ltr'}
                                    }}
                                />
                            ))}
                        </Box>

                        {otpError && (
                            <Typography color="error" sx={{textAlign: 'center', marginBottom: 2}}>
                                {otpError}
                            </Typography>
                        )}

                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: 2,
                            marginTop: 1
                        }}>
                            <Button
                                onClick={handleResendOtp}
                                disabled={countdown > 0 || isLoading}
                                sx={{
                                    color: countdown > 0 ? 'gray' : '#1087FF',
                                    textDecoration: countdown > 0 ? 'none' : 'underline',
                                    fontWeight: 'bold',
                                }}
                            >
                                ارسال مجدد کد
                            </Button>

                            {countdown > 0 && (
                                <Typography variant="body2" color="text.secondary">
                                    {formatTime(countdown)}
                                </Typography>
                            )}
                        </Box>
                    </>
                )}

                {apiError && (
                    <FormHelperText error sx={{textAlign: 'center', fontSize: '0.9rem', marginTop: 1}}>
                        {apiError}
                    </FormHelperText>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    disabled={isLoading}
                    sx={{
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        fontWeight: 'bold',
                        borderRadius: '10px',
                        '&:hover': {
                            color: '#FF0000',
                            borderColor: '#FF0000',
                            backgroundColor: 'rgba(255, 0, 0, 0.04)',
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                        '&.MuiButtonBase-root:focus-visible': {
                            outline: 'none',
                            boxShadow: 'none',
                        }
                    }}
                >
                    انصراف
                </Button>
                <Button
                    onClick={step === 1 ? handleSendOtp : handleVerifyOtp}
                    variant="contained"
                    disabled={isLoading || success}
                    sx={{
                        color: 'white',
                        borderColor: '#1087FF',
                        backgroundColor: '#1087FF',
                        fontWeight: 'bold',
                        borderRadius: '10px',
                        width: 'auto',
                        minWidth: '120px',
                        padding: '6px 16px',
                    }}
                >
                    {isLoading ? <CircularProgress size={24} color="inherit"/> :
                        step === 1 ? 'ارسال کد تأیید' : 'تأیید'}
                </Button>
            </DialogActions>
            <Snackbar
                open={success}
                autoHideDuration={5000}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert severity="success" sx={{width: '100%', fontFamily: 'IRANSans !important'}}>
                    شماره تلفن با موفقیت ویرایش شد
                </Alert>
            </Snackbar>
        </StyledDialog>
    );
};

export default ChangePhoneModal;