import {useNavigate, useParams} from "react-router-dom";
import styles from "../../../styles/auditor/desktop/AuditorInquiry.module.css"
import React, {useEffect, useState, useRef} from "react";
import {useReactToPrint} from 'react-to-print';
import LoadingComponent from "../../../components/Loading/Loading";
import convertDateToShamsi from "../../../utils/functions";
import {FaStar} from "@react-icons/all-files/fa/FaStar";
import {FaLongArrowAltLeft} from "@react-icons/all-files/fa/FaLongArrowAltLeft";
import CustomTable from "../../../components/CustomTable/CustomTable";
import AutoGrowInput from "../../../components/AutoGrowInput";
import toast_style from "../../../styles/Toastify.css";
import {toast, ToastContainer} from "react-toastify";
import emptyTable from "../../../assets/images/empty_table.svg"
import PDFPreview from "../../../components/PDFPreview/PDFPreview";
import {addResult, completeReceiptsProcessing} from "../../../APIs/receipt";
import {addComment, getPhoto, returnInquiry} from "../../../APIs/inquiry";
import ReturnConfirmationModal from "../../../components/InquiryReturnConfirmation/ReturnConfirmationModal";
import TimeLine from "../../../components/TimeLine/TimeLine";
import ReceiptProcessingModal from "./ReceiptProcessingModal";
import InquiryStatusIcon from "../../../components/InquiryStatusIcon";
import ConfirmationDialog from "../../../components/Confirmation/ConfirmationDialog";
import useFileDownload from "../../../hooks/useFileDownload";
import useFetchInquiry from "../../../hooks/useFetchInquiry";
import useComments from "../../../hooks/useComments";
import useInquiryTable from "../../../hooks/useInquiryTable";
import InquiryProductsInfo from "../../../components/InquiryProductsInfo";
import ReactLoading from "react-loading";
import {CircularProgress} from "@mui/material";
import ApprovedReceiptDocument from "../../common/ApprovedReceiptDocument";

const AuditorInquiryDesktop = ({}) => {
    const navigate = useNavigate();
    const {id} = useParams();
    const [filePreviewLoading, setFilePreviewLoading] = useState(false);

    // Product Modal
    const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
    const [showReceiptProcessingModal, setShowReceiptProcessingModal] = useState(false);

    // State variables fetched in useEffect
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const [selectedReceiptPhoto, setSelectedReceiptPhoto] = useState(null);
    const [selectedReceiptForEdit, setSelectedReceiptForEdit] = useState(null);
    const [isSuggestion, setIsSuggestion] = useState(false);

    const [refetch, setRefetch] = useState(false);
    const [showProcessingCompletionConfirmation, setShowProcessingCompletionConfirmation] = useState(false);
    const [showAddingResultConfirmation, setShowAddingResultConfirmation] = useState(false);

    // Convert inquiry approval to PDF
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    //

    // Download File
    const {downloadFile, isDownloading} = useFileDownload(id);
    const handleDownload = (photo_id, index) => {
        downloadFile(photo_id, index);
    };
    //

    // File Preview
    async function handleSelectReceiptPhoto(photo_id) {
        setFilePreviewLoading(true);
        try {
            const photo = await getPhoto(photo_id, id);
            if (photo?.data.photo) {
                const {file_format, data_uri} = photo.data.photo;
                file_format === "pdf" ? handlePdfClick(data_uri) : setSelectedReceiptPhoto(data_uri);
            }
        } catch (error) {
            console.error("Error fetching photo:", error);
        } finally {
            setFilePreviewLoading(false);
        }
    }

    const [selectedPdf, setSelectedPdf] = useState(null);
    const handlePdfClick = (pdfUrl) => {
        setSelectedPdf(pdfUrl);
    };
    //

    // Fetch Inquiry
    const {
        inquiryLoading,
        receiptsLoading,
        error,
        inquiryDetail,
        receiptsDetail,
        selectedProducts,
        areReceiptsProcessed,
        expansionData,
        fetchAll
    } = useFetchInquiry(id);

    useEffect(() => {
        fetchAll();
    }, [refetch]);
    //

    // Inquiry Table
    const [expandedRows, setExpandedRows] = useState([]);
    const handleExpandRow = (index) => {
        setExpandedRows((prevExpandedRows) =>
            prevExpandedRows.includes(index)
                ? prevExpandedRows.filter((row) => row !== index)
                : [...prevExpandedRows, index]
        );
    };

    const {
        tableHeader,
        tableRows,
        selectedReceipt
    } = useInquiryTable(
        inquiryDetail,
        receiptsDetail,
        handleSelectReceiptPhoto,
        handleDownload,
        handleExpandRow,
        expandedRows,
        true,
        handleEditReceipt,
    );
    //

    // Comment
    const {
        newComment,
        timelineItems,
        handleCommentChange,
        handleAddNewComment,
        isFetchingComments,
        isSendingComment
    } = useComments(id);
    //

    const handleSelectReceipt = (photo_id, index) => {
        setSelectedPhoto(photo_id);
        setShowReceiptProcessingModal(true);
        setSelectedPhotoIndex(index);
    };

    const handleCloseReceiptProcessingModal = () => {
        setShowReceiptProcessingModal(false);
        setSelectedReceiptForEdit(null);
        setRefetch(prev => !prev);
    };

    // Return an inquiry
    const [showReturnConfirmation, setShowReturnConfirmation] = useState(false);
    const handleReturnInquiry = () => {
        setShowReturnConfirmation(true);
    };
    const handleCloseReturnConfirmation = () => {
        setShowReturnConfirmation(false);
    };
    const handleConfirmReturn = async (explanation) => {
        try {
            await returnInquiry(id);
            await addComment(id, explanation, false);
        } catch (error) {
            console.error('Error adding comment or returning inquiry: ', error);
        } finally {
            setShowReturnConfirmation(false);
            navigate('/auditor');
        }
    };

    function handleEditReceipt(photo_id, index) {
        setSelectedPhoto(photo_id);
        setSelectedReceiptForEdit(receiptsDetail[index]);
        setShowReceiptProcessingModal(true);
    }

    function hasReceiptProcessed(photo_id) {
        for (let i = 0; i < receiptsDetail.length; i++) {
            if (receiptsDetail[i].photo === photo_id) {
                return true;
            }
        }
        return false;
    }

    const handleAddSuggestion = () => {
        setShowReceiptProcessingModal(true);
        setIsSuggestion(true);
    };

    const handleCompleteProcessing = async () => {
        if (areReceiptsProcessed) {
            await completeReceiptsProcessing(id);
            window.location.reload();
        } else {
            toast.warning("ابتدا باید تمام پیش‌فاکتور‌ها پردازش شوند.", {autoClose: 3000});
        }
    };

    const handleAddResult = async () => {
        // setLoading(true);
        try {
            await addResult(id, selectedReceipt);
        } catch (error) {
            console.error('Error creating result:', error);
        } finally {
            // setLoading(false);
            setShowAddingResultConfirmation(false);
            window.location.reload();
        }
    };

    const InquiryDetailsAndUnprocessedReceiptsColumn = ({inquiryDetail}) => {
        return (
            <div className={styles.FirstRow}>
                <div className={styles.InquiryDetails}>
                    <span className={styles.AuditorInquiryPageHeader}>جزئیات درخواست</span>
                    <div className={styles.AuditorInquiryInputField}>
                        <span className={styles.InputText}>تاریخ ثبت در سامانه:</span>
                        <span
                            className={styles.InputAnswer}>{convertDateToShamsi(inquiryDetail.inquiry.submitted_date)}</span>
                    </div>
                    {inquiryDetail.result?.date &&
                        <div className={styles.AuditorInquiryInputField}>
                            <span className={styles.InputText}>تاریخ بررسی:</span>
                            <span
                                className={styles.InputAnswer}>{convertDateToShamsi(inquiryDetail.result.date)}
                            </span>
                        </div>
                    }
                    <div className={styles.AuditorInquiryInputField}>
                        <span className={styles.InputText}>نام ایجاد کننده:</span>
                        <span className={styles.InputAnswer}>{inquiryDetail.inquiry.fullname}</span>
                    </div>
                    <div className={styles.AuditorInquiryInputField}>
                        <span className={styles.InputText}>نام شرکت:</span>
                        <span className={styles.InputAnswer}>{inquiryDetail.inquiry.organization}</span>
                    </div>
                </div>
                <div className={styles.Product}>
                    <span className={styles.AuditorInquiryPageHeader}>کالاهای این درخواست</span>
                    {receiptsLoading ?
                        <div className={styles.loading_container}>
                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)" width='50px'
                                          height='50px'/>
                        </div>
                        :
                        <InquiryProductsInfo selectedProducts={selectedProducts}/>
                    }
                </div>
            </div>
        );
    };

    const ActionButtons = ({
                               inquiryDetail,
                               areReceiptsProcessed,
                               handleCompleteProcessing,
                               handleAddResult,
                               navigate,
                               handleReturnInquiry
                           }) => (
        <div className={styles.Buttons}>
            {inquiryDetail.inquiry.status === 1 ? (
                <button
                    className={`${styles.CompleteProcessing} ${!areReceiptsProcessed ? styles.disabled : ''}`}
                    onClick={() => setShowProcessingCompletionConfirmation(true)}
                >
                    اتمام پردازش
                </button>
            ) : inquiryDetail.inquiry.status === 2 ? (
                <button className={styles.CompleteProcessing} onClick={() => {
                    if (selectedReceipt === null) {
                        toast.warn("ابتدا پیش‌فاکتور مورد تایید خود را انتخاب نمایید.", {autoClose: 3000})
                    } else {
                        setShowAddingResultConfirmation(true);
                    }
                }}>
                    اتمام بررسی
                </button>
            ) : null}
            {inquiryDetail.inquiry.status === 4 &&
                <button className={styles.CompleteProcessing} onClick={handlePrint}>
                    دریافت رسید درخواست
                </button>
            }
            <button className={styles.BackButton} onClick={() => navigate(`/auditor`)}>
                بازگشت
            </button>
            {inquiryDetail.inquiry.status === 1 &&
                <button
                    className={styles.ReturnButton}
                    onClick={handleReturnInquiry}
                    disabled={inquiryDetail.inquiry.is_returned}
                >
                    ارجاع درخواست
                </button>
            }
            {inquiryDetail.inquiry.status === 4 &&
                <div style={{display: 'none'}}>
                    <ApprovedReceiptDocument ref={componentRef}/>
                </div>
            }
            {showProcessingCompletionConfirmation && (
                <ConfirmationDialog
                    message="آیا از اتمام پردازش پیش‌فاکتور‌های این درخواست اطمینان دارید؟"
                    onConfirm={handleCompleteProcessing}
                    onCancel={() => setShowProcessingCompletionConfirmation(false)}
                />
            )}
            {showAddingResultConfirmation && (
                <ConfirmationDialog
                    message="آیا از اتمام بررسی این درخواست اطمینان دارید؟"
                    onConfirm={handleAddResult}
                    onCancel={() => setShowAddingResultConfirmation(false)}
                />
            )}
        </div>
    );

    const Modals = ({
                        showReceiptProcessingModal,
                        selectedReceiptPhoto,
                        selectedPdf,
                        showReturnConfirmation,
                        handleCloseReceiptProcessingModal,
                        setSelectedReceiptPhoto,
                        setSelectedPdf,
                        handleCloseReturnConfirmation,
                        handleConfirmReturn,
                        selectedPhoto,
                        selectedReceiptForEdit,
                        isSuggestion
                    }) => (
        <>
            {showReceiptProcessingModal && (
                <ReceiptProcessingModal
                    onClose={handleCloseReceiptProcessingModal}
                    receiptName={`پیش‌فاکتور شماره ${selectedPhotoIndex + 1}`}
                    receiptFileId={selectedPhoto}
                    selectedReceiptForEdit={selectedReceiptForEdit}
                    isSuggestion={isSuggestion}
                    selectedProducts={selectedProducts}
                />
            )}
            {selectedReceiptPhoto && (
                <div className={styles.enlargedImageContainer} onClick={() => setSelectedReceiptPhoto('')}>
                    <img src={selectedReceiptPhoto} alt="Enlarged Image" className={styles.enlargedImage}/>
                </div>
            )}
            {selectedPdf && (
                <PDFPreview selectedPdf={selectedPdf} setSelectedPdf={setSelectedPdf}/>
            )}
            {showReturnConfirmation && (
                <ReturnConfirmationModal
                    onClose={handleCloseReturnConfirmation}
                    onConfirm={handleConfirmReturn}
                />
            )}
        </>
    );

    if (inquiryDetail === null) {
        return (
            <LoadingComponent isMobile={false}/>
        );
    } else if (error) {
        // TODO: handle this scenario
    } else {
        return (
            <div
                className={styles.InquiryDetailsContainer}>
                <div className={styles.NameAndStatusContainer}>
                    <span className={styles.InquiryName}>
                        عنوان درخواست: <strong>{inquiryDetail.inquiry.subject}</strong>
                    </span>
                    <span className={styles.InquiryName}>
                        شناسه درخواست: <strong>{inquiryDetail.inquiry.name}</strong>
                    </span>
                    <InquiryStatusIcon inquiryStatus={inquiryDetail.inquiry.status}/>
                </div>
                <div className={styles.PageContainer}>
                    <ToastContainer className={toast_style}/>
                    <div className={styles.ColumnsContainer}>
                        <div className={styles.FirstCol}>
                            <InquiryDetailsAndUnprocessedReceiptsColumn
                                inquiryDetail={inquiryDetail}
                            />
                            <div className={styles.SecondRow}>
                                {!areReceiptsProcessed &&
                                    <div className={styles.UnProcessedReceipts}>
                                        <span className={styles.AuditorInquiryPageHeader}>
                                            پیش‌فاکتور‌های پردازش نشده
                                        </span>

                                        <span className={styles.ExplanationText}>برای پردازش هر پیش‌فاکتور روی
                                            آن
                                            کلیک کنید.</span>
                                        {inquiryDetail.inquiry.photos.map((photo, index) => (
                                            !hasReceiptProcessed(photo.id) && (
                                                <div
                                                    key={photo.id}
                                                    className={styles.ReceiptBox}
                                                    onClick={() => {
                                                        handleSelectReceipt(photo.id, index);
                                                    }}
                                                >
                                                    <span className={styles.ReceiptNumber}>پیش‌فاکتور
                                                        شماره {index + 1}</span>
                                                    {photo.is_selected_by_user && (
                                                        <span className={styles.myChoiceText}>
                                                            <FaStar/>&nbsp;&nbsp;&nbsp;انتخاب مسئول خرید
                                                        </span>
                                                    )}
                                                    <FaLongArrowAltLeft className={styles.ArrowIcon}/>
                                                </div>
                                            )
                                        ))}
                                    </div>
                                }
                                <div
                                    className={`${styles.ProcessedReceipts} ${areReceiptsProcessed ? styles.FullSize : ''}`}>
                                    <span className={styles.AuditorInquiryPageHeader}>
                                        پیش‌فاکتور‌های پردازش شده
                                    </span>
                                    {receiptsLoading ?
                                        <div className={styles.loading_container}>
                                            <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)"
                                                          width='50px'
                                                          height='50px'/>
                                        </div>
                                        :
                                        tableRows.length === 0 ?
                                            <div className={styles.EmptyTableContainer}>
                                                <img className={styles.EmptyTableImage} src={emptyTable}
                                                     alt="Empty Table"/>
                                                <span className={styles.ExplanationText}>برای شروع پردازش پیش‌فاکتورها،
                                                    ابتدا از بخش پیش‌فاکتور‌های پردازش نشده، یک پیش‌فاکتور را انتخاب
                                                    کنید.</span>
                                            </div>
                                            :
                                            <div className={styles.InquiriesTableContainer}>
                                                <CustomTable headerData={tableHeader} data={tableRows}
                                                             styles={"desktop"}
                                                             expandedRows={expandedRows} expansionData={expansionData}/>
                                            </div>
                                    }

                                    {inquiryDetail.inquiry.status === 2 && (
                                        <button className={styles.AddSuggestion} onClick={handleAddSuggestion}>
                                            اضافه کردن پیش‌فاکتور پیشنهادی
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.ThirdColumn}>
                            <div className={styles.InquiryHistoryTimeLine}>
                                <span className={styles.AuditorInquiryPageHeader}>گردش کار</span>

                                {isFetchingComments ?
                                    <div className={styles.loading_container}>
                                        <ReactLoading type="spinningBubbles" color="var(--primary-dark-color)"
                                                      width='50px'
                                                      height='50px'/>
                                    </div>
                                    :
                                    <TimeLine items={timelineItems}/>
                                }
                            </div>
                            <div className={styles.AddingCommentContainer}>
                                <span className={styles.AuditorInquiryPageHeader}>اضافه کردن کامنت</span>
                                <AutoGrowInput
                                    placeholder={""}
                                    value={newComment}
                                    onInputChange={handleCommentChange}
                                    styleClassName={styles.commentInputField}
                                />
                                <button
                                    className={styles.SendButton}
                                    onClick={handleAddNewComment}
                                    disabled={isSendingComment}
                                >
                                    {isSendingComment ? 'لطفا صبر کنید ...' : 'ارسال'}
                                </button>
                            </div>
                        </div>
                    </div>
                    <ActionButtons
                        inquiryDetail={inquiryDetail}
                        areReceiptsProcessed={areReceiptsProcessed}
                        handleCompleteProcessing={handleCompleteProcessing}
                        handleAddResult={handleAddResult}
                        navigate={navigate}
                        handleReturnInquiry={handleReturnInquiry}
                    />
                </div>
                <Modals
                    showReceiptProcessingModal={showReceiptProcessingModal}
                    selectedReceiptPhoto={selectedReceiptPhoto}
                    selectedPdf={selectedPdf}
                    showReturnConfirmation={showReturnConfirmation}
                    handleCloseReceiptProcessingModal={handleCloseReceiptProcessingModal}
                    setSelectedReceiptPhoto={setSelectedReceiptPhoto}
                    setSelectedPdf={setSelectedPdf}
                    handleCloseReturnConfirmation={handleCloseReturnConfirmation}
                    handleConfirmReturn={handleConfirmReturn}
                    selectedPhoto={selectedPhoto}
                    selectedReceiptForEdit={selectedReceiptForEdit}
                    isSuggestion={isSuggestion}
                />
                {(isDownloading || filePreviewLoading) && (
                    <div className={styles.downloadOverlay}>
                        <CircularProgress size={100}/>
                    </div>
                )}
            </div>
        );
    }
}

export default AuditorInquiryDesktop;
