import {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import {addComment} from '../APIs/inquiry';
import fetchComments from "../pages/common/utils";

const useComments = (id) => {
    const [newComment, setNewComment] = useState('');
    const [timelineItems, setTimelineItems] = useState([]);
    const [shouldRefetchComments, setShouldRefetchComments] = useState(false);
    const [isFetchingComments, setIsFetchingComments] = useState(false);
    const [isSendingComment, setIsSendingComment] = useState(false);

    useEffect(() => {
        const fetchCommentsData = async () => {
            setIsFetchingComments(true);
            try {
                await fetchComments(id, setTimelineItems);
            } catch (error) {
                console.error("Error fetching comments:", error);
                toast.error("خطا در دریافت نظرات.", {autoClose: 3000});
            } finally {
                setIsFetchingComments(false);
            }
        };

        if (id) {
            fetchCommentsData();
        }
    }, [id, shouldRefetchComments]);

    const handleCommentChange = (value) => {
        setNewComment(value);
    };

    const handleAddNewComment = async () => {
        if (!newComment.trim()) {
            toast.warning("لطفا یک کامنت وارد کنید.", {autoClose: 3000});
            return;
        }

        setIsSendingComment(true);
        try {
            await addComment(id, newComment.trim(), true);
            // toast.success("کامنت جدید با موفقیت اضافه شد.", {autoClose: 3000});
        } catch (error) {
            console.error("Error adding comment:", error);
            toast.error("خطا در افزودن کامنت جدید.", {autoClose: 3000});
        } finally {
            setIsSendingComment(false);
            setNewComment('');
            setShouldRefetchComments(prev => !prev);
        }
    };

    return {
        newComment,
        timelineItems,
        handleCommentChange,
        handleAddNewComment,
        isFetchingComments,
        isSendingComment
    };
};

export default useComments;