import React, {useState, useEffect} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Box,
    Typography,
    Switch,
    CircularProgress,
    Snackbar,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {Alert} from '@mui/lab';
import {
    getUserSMSNotificationPreferences,
    updateUserNotificationSettings
} from '../../APIs/user';

const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-paper': {
        width: '500px',
        maxWidth: '90vw',
        padding: theme.spacing(2),
    },
    '& *': {
        fontFamily: 'IRANSans !important',
    },
    '& .MuiDialogTitle-root': {
        padding: theme.spacing(1, 2),
        textAlign: 'center',
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1, 2),
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        '& .MuiButton-root': {
            width: '20%',
        }
    },
}));

const CustomSwitch = styled(Switch)(({theme}) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#1087FF',
        '&:hover': {
            backgroundColor: 'rgba(16, 135, 255, 0.08)',
        },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
        backgroundColor: '#1087FF',
    },
}));

const NotificationSettingsModal = ({open, onClose}) => {
    const [settings, setSettings] = useState({});
    const [loading, setLoading] = useState(true);
    const [saving, setSaving] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');

    useEffect(() => {
        if (open) {
            fetchNotificationSettings();
        }
    }, [open]);

    const fetchNotificationSettings = async () => {
        try {
            setLoading(true);
            const response = await getUserSMSNotificationPreferences();
            setSettings(response.data);
        } catch (error) {
            console.error('Error fetching notification settings:', error);
            setError('خطا در دریافت تنظیمات اعلان‌ها. لطفا دوباره تلاش کنید.');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        const name = event.target.name;
        const checked = event.target.checked;

        setSettings(prev => ({
            ...prev,
            [name]: checked
        }));
    };

    const handleSave = async () => {
        try {
            setSaving(true);
            setError('');
            await updateUserNotificationSettings(settings);
            setSuccess(true);

            // Close modal after a delay
            setTimeout(() => {
                onClose();
            }, 2000);
        } catch (error) {
            console.error('Error saving notification settings:', error);
            setError('خطا در ذخیره تنظیمات. لطفا دوباره تلاش کنید.');
        } finally {
            setSaving(false);
        }
    };

    const handleClose = () => {
        setSuccess(false);
        setError('');
        onClose();
    };


    const settingsItems = {
        'notify_on_status_change_to_checking': ['تغییر وضعیت درخواست به در حال بررسی', 'دریافت اعلان هنگام تغییر وضعیت درخواست به "در حال بررسی"'],
        'notify_on_status_change_to_checked': ['تغییر وضعیت درخواست به بررسی شده', 'دریافت اعلان هنگام تغییر وضعیت درخواست به "بررسی شده"'],
        'notify_on_inquiry_returned': ['مرجوع شدن درخواست', 'دریافت اعلان هنگام مرجوع شدن درخواست'],
        'notify_on_comment_added': ['اضافه شدن کامنت جدید', 'دریافت اعلان هنگام افزودن کامنت جدید'],
        'notify_on_inquiry_created': ['اضافه شدن درخواست جدید', 'دریافت اعلان هنگام اضافه شدن درخواست توسط مسئول تدارکات'],
        'notify_on_inquiry_approved': ['تغییر وضعیت درخواست به تایید شده', 'دریافت اعلان هنگام تایید نهایی درخواست توسط مسئول تدارکات']
    }

    return (
        <StyledDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="notification-settings-dialog-title"
            dir="rtl"
        >
            <DialogTitle id="notification-settings-dialog-title" color="grey" fontWeight="bold">
                تنظیمات اعلان‌ها
            </DialogTitle>

            <DialogContent>
                {loading ? (
                    <Box sx={{display: 'flex', justifyContent: 'center', padding: '20px'}}>
                        <CircularProgress size={40} sx={{color: '#1087FF'}}/>
                    </Box>
                ) : (
                    <Box sx={{mt: 2}}>
                        <Typography variant="body2" sx={{mb: 3, textAlign: 'center', color: 'text.secondary'}}>
                            مشخص کنید برای کدام وضعیت‌ها می‌خواهید اعلان دریافت کنید
                        </Typography>

                        {Object.entries(settings).map(([key, value], index, entries) => (
                            <Box
                                key={key}
                                sx={{
                                    py: 2,
                                    borderBottom: index === entries.length - 1 ? 'none' : '1px solid #f0f0f0',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center'
                                }}
                            >
                                <Box>
                                    <Typography variant="body1" sx={{fontWeight: 'bold', mb: 0.5}}>
                                        {settingsItems[key][0]}
                                    </Typography>
                                    <Typography variant="body2" sx={{color: 'text.secondary'}}>
                                        {settingsItems[key][1]}
                                    </Typography>
                                </Box>
                                <CustomSwitch
                                    checked={value}
                                    onChange={handleChange}
                                    name={key}
                                />
                            </Box>
                        ))}

                        {error && (
                            <Typography
                                color="error"
                                sx={{textAlign: 'center', mt: 2, fontSize: '0.9rem'}}
                            >
                                {error}
                            </Typography>
                        )}
                    </Box>
                )}
            </DialogContent>

            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    disabled={saving}
                    sx={{
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        fontWeight: 'bold',
                        borderRadius: '10px',
                        '&:hover': {
                            color: '#FF0000',
                            borderColor: '#FF0000',
                            backgroundColor: 'rgba(255, 0, 0, 0.04)',
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                        '&.MuiButtonBase-root:focus-visible': {
                            outline: 'none',
                            boxShadow: 'none',
                        }
                    }}
                >
                    انصراف
                </Button>
                <Button
                    onClick={handleSave}
                    variant="contained"
                    disabled={loading || saving || success}
                    sx={{
                        color: 'white',
                        borderColor: '#1087FF',
                        backgroundColor: '#1087FF',
                        fontWeight: 'bold',
                        borderRadius: '10px',
                        width: 'auto',
                        minWidth: '150px',
                        padding: '6px 16px',
                    }}
                >
                    {saving ? <CircularProgress size={24} color="inherit"/> : 'ذخیره تنظیمات'}
                </Button>
            </DialogActions>

            <Snackbar
                open={success}
                autoHideDuration={5000}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert severity="success" sx={{width: '100%', fontFamily: 'IRANSans !important'}}>
                    تنظیمات اعلان‌ها با موفقیت ذخیره شد
                </Alert>
            </Snackbar>
        </StyledDialog>
    );
};

export default NotificationSettingsModal;