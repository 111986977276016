import React, {useState} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    FormHelperText,
    CircularProgress, Link, Typography, Snackbar,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {changePassword} from '../../APIs/user';
import {useNavigate} from 'react-router-dom';
import {Alert} from "@mui/lab";


const StyledDialog = styled(Dialog)(({theme}) => ({
    '& .MuiDialog-paper': {
        width: '600px',
        maxWidth: '90vw',
        padding: theme.spacing(2),
    },
    '& *': {
        fontFamily: 'IRANSans !important',
    },
    '& .MuiDialogTitle-root': {
        padding: theme.spacing(1, 2),
        textAlign: 'center',
    },
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1, 2),
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        '& .MuiButton-root': {
            width: '20%',
        }
    },
    '& .MuiTextField-root': {
        marginBottom: theme.spacing(2),
    },
}));

const ChangePasswordModal = ({open, onClose}) => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        old_password: '',
        new_password: '',
        confirmPassword: '',
    });

    const [errors, setErrors] = useState({
        old_password: '',
        new_password: '',
        confirmPassword: '',
    });

    const [isLoading, setIsLoading] = useState(false);
    const [apiError, setApiError] = useState('');
    const [success, setSuccess] = useState(false);

    const validatePassword = (password) => {
        const validChars = /^[a-zA-Z0-9_@#%&]*$/;

        if (password.length < 8) {
            return 'رمز عبور باید حداقل ۸ کاراکتر باشد.';
        }

        if (!validChars.test(password)) {
            return 'رمز عبور فقط می‌تواند شامل حروف، اعداد و کاراکترهای _ @ # % & باشد.';
        }

        return '';
    };

    const handleChange = (event) => {
        const {name, value} = event.target;

        setFormData(prev => ({
            ...prev,
            [name]: value
        }));

        setApiError('');

        if (name === 'new_password') {
            const newPasswordError = validatePassword(value);
            setErrors(prev => ({
                ...prev,
                new_password: newPasswordError,
                confirmPassword: value !== formData.confirmPassword ? 'تکرار رمز عبور مطابقت ندارد.' : ''
            }));
        }

        if (name === 'confirmPassword') {
            setErrors(prev => ({
                ...prev,
                confirmPassword: value !== formData.new_password ? 'تکرار رمز عبور مطابقت ندارد.' : ''
            }));
        }
    };

    const handleSubmit = async () => {
        const newErrors = {
            old_password: !formData.old_password ? 'لطفاً رمز عبور فعلی را وارد کنید.' : '',
            new_password: validatePassword(formData.new_password),
            confirmPassword: formData.new_password !== formData.confirmPassword ? 'تکرار رمز عبور مطابقت ندارد.' : ''
        };

        setErrors(newErrors);

        if (Object.values(newErrors).some(error => error !== '')) {
            return;
        }

        setIsLoading(true);
        setApiError('');

        try {
            const response = await changePassword(formData.old_password, formData.new_password);
            setSuccess(true);
            setTimeout(() => {
                onClose();
                setFormData({old_password: '', new_password: '', confirmPassword: ''});
                setSuccess(false);
            }, 3000);
        } catch (error) {
            if (error.response) {
                if (error.response.data.error === "Old password is incorrect.") {
                    setErrors(prev => ({
                        ...prev,
                        old_password: 'رمز عبور فعلی اشتباه است.'
                    }));
                } else {
                    setApiError(error.response.data.error || 'خطایی رخ داده است. لطفا مجددا تلاش کنید.');
                }
            } else {
                setApiError('خطا در اتصال به سرور. لطفا مجددا تلاش کنید.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = () => {
        setFormData({old_password: '', new_password: '', confirmPassword: ''});
        setErrors({old_password: '', new_password: '', confirmPassword: ''});
        setApiError('');
        setSuccess(false);
        onClose();
    };

    const handleForgotPassword = () => {
        handleClose();
        navigate('/forgot-password');
    };

    return (
        <StyledDialog
            open={open}
            onClose={handleClose}
            aria-labelledby="change-password-dialog-title"
            dir="rtl"
        >
            <DialogTitle id="change-password-dialog-title" color="grey" fontWeight="bold">
                ویرایش رمز عبور
            </DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    name="old_password"
                    label="رمز عبور فعلی"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={formData.old_password}
                    onChange={handleChange}
                    error={!!errors.old_password}
                    helperText={errors.old_password}
                    InputProps={{
                        style: {textAlign: 'right', direction: 'rtl'}
                    }}
                    InputLabelProps={{
                        style: {left: 'auto', right: '1.75rem'}
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& > fieldset': {
                                textAlign: 'right',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            transformOrigin: 'right !important'
                        },
                    }}
                />
                <TextField
                    margin="dense"
                    name="new_password"
                    label="رمز عبور جدید"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={formData.new_password}
                    onChange={handleChange}
                    error={!!errors.new_password}
                    helperText={errors.new_password}
                    InputProps={{
                        style: {textAlign: 'right', direction: 'rtl'}
                    }}
                    InputLabelProps={{
                        style: {left: 'auto', right: '1.75rem'}
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& > fieldset': {
                                textAlign: 'right',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            transformOrigin: 'right !important'
                        },
                    }}
                />
                <TextField
                    margin="dense"
                    name="confirmPassword"
                    label="تکرار رمز عبور جدید"
                    type="password"
                    fullWidth
                    variant="outlined"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    error={!!errors.confirmPassword}
                    helperText={errors.confirmPassword}
                    InputProps={{
                        style: {textAlign: 'right', direction: 'rtl'}
                    }}
                    InputLabelProps={{
                        style: {left: 'auto', right: '1.75rem'}
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            '& > fieldset': {
                                textAlign: 'right',
                            },
                        },
                        '& .MuiInputLabel-root': {
                            transformOrigin: 'right !important'
                        },
                    }}
                />
                <FormHelperText sx={{textAlign: 'center', marginBottom: 2, fontSize: '0.8rem'}}>
                    رمز عبور باید حداقل ۸ کاراکتر باشد و فقط می‌تواند شامل حروف، اعداد و کاراکترهای _ @ # % & باشد.
                </FormHelperText>

                {apiError && (
                    <FormHelperText error sx={{textAlign: 'right', fontSize: '0.9rem', marginBottom: 1}}>
                        {apiError}
                    </FormHelperText>
                )}

                <Typography sx={{textAlign: 'center', marginTop: 2, display: 'flex', justifyContent: 'center'}}>
                    <Link
                        component="button"
                        variant="body2"
                        onClick={handleForgotPassword}
                        sx={{
                            textDecoration: 'underline',
                            color: '#1087FF',
                            fontWeight: 'bold',
                            cursor: 'pointer',
                        }}
                    >
                        رمز عبور خود را فراموش کرده‌اید؟
                    </Link>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                    variant="outlined"
                    disabled={isLoading}
                    sx={{
                        color: '#FF0000',
                        borderColor: '#FF0000',
                        fontWeight: 'bold',
                        borderRadius: '10px',
                        '&:hover': {
                            color: '#FF0000',
                            borderColor: '#FF0000',
                            backgroundColor: 'rgba(255, 0, 0, 0.04)',
                        },
                        '&:focus': {
                            outline: 'none',
                        },
                        '&.MuiButtonBase-root:focus-visible': {
                            outline: 'none',
                            boxShadow: 'none',
                        }
                    }}
                >
                    انصراف
                </Button>
                <Button
                    onClick={handleSubmit}
                    variant="contained"
                    disabled={isLoading || success}
                    sx={{
                        color: 'white',
                        borderColor: '#1087FF',
                        backgroundColor: '#1087FF',
                        fontWeight: 'bold',
                        borderRadius: '10px'
                    }}
                >
                    {isLoading ? <CircularProgress size={24} color="inherit"/> : 'ثبت'}
                </Button>
            </DialogActions>
            <Snackbar
                open={success}
                autoHideDuration={5000}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert severity="success" sx={{width: '100%', fontFamily: 'IRANSans !important'}}>
                    رمز عبور با موفقیت ویرایش شد
                </Alert>
            </Snackbar>
        </StyledDialog>
    );
};

export default ChangePasswordModal;