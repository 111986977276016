import axios from './base';

export function signup(fullname, phone, email, organization_code, username, password, otp_verification_token) {
    return axios.post('user/add-user/', {
        fullname: fullname,
        email: email,
        phone: phone,
        username: username,
        password: password,
        organization_code: organization_code,
        otp_verification_token: otp_verification_token
    });
}

export function login(username, password) {
    return axios.post('token/', {username: username, password: password});
}

export function logout(refresh_token) {
    return axios.post('user/logout/', {refresh_token: refresh_token}, {withCredentials: true});
}

export function refreshToken(refresh_token) {
    return axios.post('token/refresh/', {refresh: refresh_token});
}

export function fetchCurrentUser() {
    return axios.get('user/current/');
}

export function deactivateUser(user) {
    return axios.post(`user/${user.id}/deactivate/`);
}

export const sendVerificationCode = async (phone, otp_type) => {
    return await axios.post("user/send-otp/", {phone: phone, otp_type: otp_type});
};

export const verifyCode = async (phone, code) => {
    return await axios.post("user/verify-otp/", {phone: phone, otp: code});
};

export const resetPassword = async (phone, newPassword, verificationToken) => {
    return await axios.post('user/reset-password/', {
        phone: phone,
        new_password: newPassword,
        otp_verification_token: verificationToken
    });
};