import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import {sendVerificationCode, verifyCode} from "../../APIs/auth";
import InlineError from "../../components/InlineError/InlineError";
import OTPInput from "../../components/OTPInput/OTPInput";
import OTPTimer from "../../components/OTPTimer/OTPTimer";
import mobile_styles from "../../styles/auth/SignupMobile.module.css";
import desktop_styles from "../../styles/auth/SignupDesktop.module.css";

const OTPVerification = ({
                             otp_type,
                             onVerificationComplete,
                             isMobile,
                             initialPhone = '',
                             redirectOnError = true
                         }) => {
    const styles = isMobile ? mobile_styles : desktop_styles;
    const navigate = useNavigate();
    const [step, setStep] = useState(1);
    const [phone, setPhone] = useState(initialPhone);
    const [otpCode, setOtpCode] = useState('');
    const [sendOTPLoading, setSendOTPLoading] = useState(false);
    const [verifyOTPLoading, setVerifyOTPLoading] = useState(false);
    const pageSubject = otp_type === "register" ? "ثبت‌نام" : otp_type === "password_reset" ? "بازیابی رمز عبور" : "";

    const arabicToEnglishDigits = (str) => {
        try {
            str = String(str); // Convert to string if it's not already
            return str.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))
                .replace(/[۰-۹]/g, d => '۰۱۲۳۴۵۶۷۸۹'.indexOf(d));
        } catch (error) {
            console.error("Error converting Arabic digits to English:", error);
            return str; // Return the original input if an error occurs
        }
    };

    // Error states
    const [showPhoneStructureError, setShowPhoneStructureError] = useState(false);
    const [showPhoneAlreadyExistsError, setShowPhoneAlreadyExistsError] = useState(false);
    const [showOTPCodeSendFailure, setShowOTPCodeSendFailure] = useState(false);
    const [showOTPIpAddressError, setShowOTPIpAddressError] = useState(false);
    const [showOTPCodeRateLimit, setShowOTPCodeRateLimit] = useState(false);
    const [showInvalidOTP, setShowInvalidOTP] = useState(false);
    const [showPhoneNotExistError, setShowPhoneNotExistError] = useState(false);
    const [showSMSNotActiveInNight, setShowSMSNotActiveInNight] = useState(false);

    // Error messages
    const PHONE_ALREADY_EXISTS = "Phone already exists. Please try another one.";
    const PHONE_STRUCTURE_ERROR = "Phone number should start with 09 and have 11 digits.";
    const SMS_SEND_FAILURE = "Failed to send SMS."
    const SMS_LIMIT_EXCEEDED = "Too many SMS attempts. Please try again later.";
    const SMS_SEND_FAILURE_IP_ADDRESS = "Failed to send SMS. Possible invalid IP address.";
    const INVALID_OR_EXPIRED_OTP = "OTP expired or invalid.";
    const PHONE_NOT_EXIST = "Phone number does not exist.";

    const handleInputChange = (event) => {
        const {value} = event.target;
        setPhone(arabicToEnglishDigits(value));

        // Reset errors
        setShowPhoneStructureError(false);
        setShowPhoneAlreadyExistsError(false);
        setShowOTPCodeSendFailure(false);
        setShowOTPIpAddressError(false);
        setShowPhoneNotExistError(false);
    };

    const handleSubmitPhone = async (event) => {
        event.preventDefault();
        setSendOTPLoading(true);
        try {
            // Reset errors
            setShowPhoneStructureError(false);
            setShowPhoneAlreadyExistsError(false);
            setShowOTPCodeSendFailure(false);
            setShowOTPIpAddressError(false);
            setShowPhoneNotExistError(false);
            setShowSMSNotActiveInNight(true);

            await sendVerificationCode(phone, otp_type);
            setStep(2);
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.status === 400) {
                    if (error.response.data.error === PHONE_STRUCTURE_ERROR) {
                        setShowPhoneStructureError(true);
                    } else if (error.response.data.error === PHONE_ALREADY_EXISTS) {
                        setShowPhoneAlreadyExistsError(true);
                    } else if (error.response.data.error === PHONE_NOT_EXIST) {
                        setShowPhoneNotExistError(true);
                    } else if (error.response.data.error === SMS_SEND_FAILURE) {
                        setShowOTPCodeSendFailure(true);
                    } else if (error.response.data.error === SMS_SEND_FAILURE_IP_ADDRESS) {
                        setShowOTPIpAddressError(true);
                    } else if (error.response.data.error === "OTP service is not available between 10 PM and 8 AM.") {
                        setShowSMSNotActiveInNight(true);
                    } else if (redirectOnError) {
                        navigate("/500");
                    }
                } else if (redirectOnError) {
                    navigate("/500");
                }
            } else if (redirectOnError) {
                navigate("/500");
            }
        } finally {
            setSendOTPLoading(false);
        }
    };

    const handleSubmitOTP = async (code, event) => {
        if (event) event.preventDefault();
        setVerifyOTPLoading(true);
        try {
            setShowOTPCodeRateLimit(false);
            setShowInvalidOTP(false);

            const response = await verifyCode(phone, code || otpCode);
            const verificationToken = response.data.verification_token;

            // Pass both the phone and token back to the parent component
            onVerificationComplete(phone, verificationToken);
        } catch (error) {
            if (error.response && error.response.data) {
                if (error.response.status === 400) {
                    if (error.response.data.error === SMS_LIMIT_EXCEEDED) {
                        setShowOTPCodeRateLimit(true);
                    } else if (error.response.data.error === INVALID_OR_EXPIRED_OTP) {
                        setShowInvalidOTP(true);
                    } else if (redirectOnError) {
                        navigate("/500");
                    }
                } else if (redirectOnError) {
                    navigate("/500");
                }
            } else if (redirectOnError) {
                navigate("/500");
            }
        } finally {
            setVerifyOTPLoading(false);
        }
    };

    return (
        <div className={styles.SignupContent}>
            <h3 className={styles.HeadingThree}>ایران‌تدارکات</h3>
            <div className={styles.OTPContent}>
                <h2 className={styles.HeadingTwo}>{pageSubject}</h2>
                {step === 1 ?
                    <>
                        <span className={styles.OtpInfo}>
                            برای {pageSubject}، ابتدا شماره موبایل خود را وارد کنید.
                        </span>
                        <form onSubmit={handleSubmitPhone} className={styles.SignupForm}>
                            <input
                                type="phone"
                                id="phone"
                                name="phone"
                                placeholder="شماره موبایل"
                                className={styles.OTPInputField}
                                value={phone}
                                onChange={handleInputChange}
                                onInvalid={(e) => {
                                    e.target.setCustomValidity('لطفا این قسمت را پر کنید.');
                                }}
                                onInput={(e) => {
                                    e.target.setCustomValidity('');
                                }}
                                required
                            />
                            {showPhoneStructureError && (
                                <div style={{alignSelf: 'center'}}>
                                    <InlineError
                                        errorMessage={"ساختار شماره تلفن همراه وارد شده صحیح نمی‌باشد."}
                                        device={isMobile ? "mobile" : "desktop"}
                                    />
                                </div>
                            )}
                            {showPhoneAlreadyExistsError && (
                                <div style={{alignSelf: 'center'}}>
                                    <InlineError
                                        errorMessage={"با این شماره تلفن همراه قبلا یک حساب ثبت شده است."}
                                        device={isMobile ? "mobile" : "desktop"}
                                    />
                                </div>
                            )}
                            {showPhoneNotExistError &&
                                <div style={{alignSelf: 'center'}}>
                                    <InlineError
                                        errorMessage={"شماره تلفن وارد شده در سیستم ثبت نشده است."}
                                        device={isMobile ? "mobile" : "desktop"}
                                    />
                                </div>
                            }
                            {showOTPCodeSendFailure && (
                                <div style={{alignSelf: 'center'}}>
                                    <InlineError
                                        errorMessage={"خطایی در ارسال کد یک‌بار مصرف رخ داده است. لطفا چند ثانیه دیگر مجددا تلاش کنید."}
                                        device={isMobile ? "mobile" : "desktop"}
                                    />
                                </div>
                            )}
                            {showOTPIpAddressError && (
                                <div style={{alignSelf: 'center'}}>
                                    <InlineError
                                        errorMessage={"خطایی در ارسال کد یک‌بار مصرف رخ داده است. لطفاً از خاموش بودن فیلترشکن خود اطمینان حاصل کنید."}
                                        device={isMobile ? "mobile" : "desktop"}
                                    />
                                </div>
                            )}
                            {showSMSNotActiveInNight && (
                                <div style={{alignSelf: 'center'}}>
                                    <InlineError
                                        errorMessage={"سرویس پیامک از ساعت ۱۰ شب تا ۸ صبح غیرفعال می‌باشد."}
                                        device={isMobile ? "mobile" : "desktop"}
                                    />
                                </div>
                            )}
                            <button type="submit" className={styles.OPTButton} disabled={sendOTPLoading}>
                                {sendOTPLoading ? "لطفا صبر کنید ..." : "ادامه"}
                            </button>
                        </form>
                    </>
                    :
                    <>
                        <span className={styles.OtpInfo}>
                            کد پیامک شده به شماره {phone} را وارد کنید.
                        </span>
                        <form className={styles.SignupForm} onSubmit={(e) => handleSubmitOTP(otpCode, e)}>
                            <OTPInput
                                length={6}
                                onComplete={(otp) => setOtpCode(otp)}
                                onStartEditing={() => {
                                    setShowOTPCodeRateLimit(false);
                                    setShowInvalidOTP(false);
                                }}
                            />

                            {showOTPCodeRateLimit && (
                                <div style={{alignSelf: 'center'}}>
                                    <InlineError
                                        errorMessage={"تعداد درخواست‌های شما بیش از حد مجاز است. لطفاً بعداً دوباره تلاش کنید."}
                                        device={isMobile ? "mobile" : "desktop"}
                                    />
                                </div>
                            )}
                            {showInvalidOTP && (
                                <div style={{alignSelf: 'center'}}>
                                    <InlineError
                                        errorMessage={"کد وارد شده صحیح نیست یا منقضی شده است. لطفا مجددا تلاش کنید."}
                                        device={isMobile ? "mobile" : "desktop"}
                                    />
                                </div>
                            )}

                            <OTPTimer duration={120} onResend={() => setStep(1)}/>

                            <button type="submit" className={styles.OPTButton}
                                    disabled={verifyOTPLoading || otpCode.length !== 6}>
                                {verifyOTPLoading ? "لطفا صبر کنید ..." : "تایید"}
                            </button>
                        </form>
                    </>
                }
            </div>
        </div>
    );
};

export default OTPVerification;